import React from 'react';

import { PortableTextBlocks } from 'types/schema';
import H1 from 'components/H1';
import InnerWrap from 'components/layout/InnerWrap';
import MainLayout from 'components/layout/MainLayout';
import OuterWrap from 'components/layout/OuterWrap';
import PortableText from 'components/PortableText';

type Props = {
  title: string | null;
  description: string | null;
  pageTitle: string;
  body: PortableTextBlocks;
};

const PolicyPageLayout: React.FC<Props> = ({ title, description, pageTitle, body, children }) => {
  return (
    <MainLayout seoProps={{ title, description }}>
      <OuterWrap bg="grey900" color="white" textAlign="center">
        <InnerWrap>
          <H1>{pageTitle}</H1>
        </InnerWrap>
      </OuterWrap>
      <OuterWrap>
        <InnerWrap maxWidth={800} sx={{ p: { mt: 3 }, h2: { mt: 5, fontFamily: 'playfair' } }}>
          <PortableText blocks={body} />
          {children}
        </InnerWrap>
      </OuterWrap>
    </MainLayout>
  );
};

export default PolicyPageLayout;
