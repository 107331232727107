import { graphql } from 'gatsby';
import React from 'react';

import { BaseSanityPage, PortableTextBlocks } from 'types/schema';
import PolicyPageLayout from 'components/layout/PolicyPageLayout';

export const pageQuery = graphql`
  query PrivacyPolicyPageQuery {
    sanityPrivacyPolicyPage(id: { eq: "-66e76805-2e83-5730-bd85-1c5aff47d7bf" }) {
      meta {
        title
        description
      }
      pageTitle
      _rawBody
    }
  }
`;
type QueryData = {
  sanityPrivacyPolicyPage: BaseSanityPage & {
    _rawBody: PortableTextBlocks;
  };
};

const PrivacyPolicyPage: React.FC<{ data: QueryData }> = ({ data }) => {
  const {
    meta: { title, description },
    pageTitle,
    _rawBody,
  } = data.sanityPrivacyPolicyPage;
  return <PolicyPageLayout {...{ title, description, pageTitle }} body={_rawBody} />;
};

export default PrivacyPolicyPage;
